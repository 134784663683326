import produce from 'immer';
import { isLocaleCode, LocaleCode } from '../../../model/locale';

interface IState {
  id: string | undefined;
  language: string | undefined;
  welcomeMessageAccepted: boolean | undefined;
  name: string | undefined;
  email: string | undefined;
}

export type TAction =
  | {
      type: 'INITIALIZE';
      id: string | undefined;
      daysSinceCreated: number | undefined;
      language: LocaleCode | undefined;
      welcomeMessageAccepted: boolean | undefined;
      name: string | undefined;
      email: string | undefined;
    }
  | { type: 'UPDATE_LANGUAGE'; language: LocaleCode }
  | { type: 'UPDATE_WELCOME_MESSAGE_ACCEPTED'; welcomeMessageAccepted: boolean };

export const initialState: IState = {
  id: undefined,
  language: undefined,
  welcomeMessageAccepted: undefined,
  name: undefined,
  email: undefined
};

export function reducer(state: IState, action: TAction) {
  switch (action.type) {
    case 'INITIALIZE':
      return produce(state, draft => {
        draft.id = action.id;
        draft.language = action.language;
        draft.welcomeMessageAccepted = action.welcomeMessageAccepted;
        draft.name = action.name;
        draft.email = action.email;
      });

    case 'UPDATE_LANGUAGE':
      return produce(state, draft => {
        if (isLocaleCode(action.language)) {
          draft.language = action.language;
        }
      });

    case 'UPDATE_WELCOME_MESSAGE_ACCEPTED':
      return produce(state, draft => {
        action.welcomeMessageAccepted != null
          ? (draft.welcomeMessageAccepted = action.welcomeMessageAccepted)
          : (draft.welcomeMessageAccepted = false);
      });

    default:
      throw new Error();
  }
}
